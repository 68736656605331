import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardTitle,
  CardText,
  Col,
  Card,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import SpentRevenueModal from "./Modals/usersModals/SpentRevenueModal";
import { useSelector } from "react-redux";
const AsBuyerTab = ({ user }) => {
  const [activeTab, setActiveTab] = useState("1");
  const { userEarnings } = useSelector((state) => state.dashboard);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const userCards = [
    {
      title: "total spent",
      earnings: `$ ${userEarnings?.totalRevenue?.toFixed(2) || 0}`,
      showModal: false,
    },

    {
      title: "Orders completed",
      earnings: userEarnings?.totalOrders || 0,
      showModal: false,
    },
    {
      title: "average buying price",
      earnings: `$ ${userEarnings?.averageSellingPrice?.toFixed(2) || 0}`,
      showModal: false,
    },

    {
      title: "Total Jobs posted",
      earnings: userEarnings?.jobPosted || 0,
      showModal: false,
    },
    {
      title: "purchases in present month",
      earnings: userEarnings?.salesInPresentMonth?.toFixed(2) || 0,
      showModal: true,
    },
  ];

  return (
    <div>
      <SpentRevenueModal modal={modal} toggle={toggleModal} />
      <Nav tabs responsive>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            User Details
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Amount Spent
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Jobs
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col md={6} sm="12">
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={user?.firstName + " " + user?.lastName}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Email Address</Label>
                <Input type="text" value={user?.email} readOnly></Input>
              </FormGroup>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type="text"
                  value={user?.phoneNumber ? user?.phoneNumber : "N/A"}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2" className="mt-5">
          <Row>
            {userCards?.map((tile, indx) => {
              return (
                <Col sm="6" className="mb-3" key={indx}>
                  <Card body className="shadow-lg">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <CardTitle className="text-capitalize">
                        {tile?.title}- {tile?.earnings}
                      </CardTitle>
                      {tile?.showModal && (
                        <div>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={toggleModal}
                          >
                            View Details
                          </Button>
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })}
            {/* 
            <Col sm="6" className="mb-3 mb-lg-0">
              <Card body className="shadow-lg">
                <CardTitle>Spent Last 6 Months - 6$</CardTitle>
                <div>
                  <Button size="sm" color="primary" onClick={toggleModal}>
                    View Details
                  </Button>
                </div>
              </Card>
            </Col> */}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AsBuyerTab;
