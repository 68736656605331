import classnames from "classnames";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { getAllOrdersAction } from "store/actions/ordersAction";
import { deleteTaxAction, getAllTaxesAction } from "store/actions/taxActions";
import ViewOrderActivity from "./Modals/orderModals/ViewOrderActivity";
import EarnedRevenueModal from "./Modals/usersModals/EarnedRevenueModal";
import SubmitTaxFileModal from "./Modals/usersModals/SubmitTaxFileModal";
import ReactPaginate from "react-paginate";
import { getUserCharts } from "store/actions/dashboardActions";
import { getCertificationAction } from "store/actions/certificationAction";
import { updateCertificateStatus } from "store/actions/certificationAction";
const AsSellerTab = ({ user, setCurrentPage, setStatus }) => {
  const { id } = useParams();
  const { allTaxes, taxLoader } = useSelector((state) => state.tax);

  const [recentTaxes, setRecentTaxes] = useState([]);

  useEffect(() => {
    const recentTax = allTaxes.slice(-1);
    // console.log("Hamza Test 1234", recentTax);
    setRecentTaxes(recentTax);
  }, [allTaxes]);

  const {
    userEarnings,
    chartStats,
    userOrders,
    totalOrderPages,
    getStatsLoader,
  } = useSelector((state) => state.dashboard);
  const { allCertification, getCertificationLoader } = useSelector(
    (state) => state.certification
  );
  const [covidCertifications, setCovidCertifications] = useState([]);
  const [Certifications, setCertifications] = useState([]);
  console.log(">>>>chartStats: ", chartStats);
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [earnRevenueModal, setEarnRevenueModal] = useState(false);
  const toggleEarnRevenueModal = () => {
    setEarnRevenueModal(!earnRevenueModal);
  };
  const [taxModal, setTaxModal] = useState(false);
  const toggleTaxModal = () => {
    setTaxModal(!taxModal);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [singleOrder, setSingleOrder] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: 11 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [chartYears, setChartYears] = useState([]);
  const [selectedChartYear, setSelectedChartYear] = useState({});
  const colors = ["#008080"];
  const [orders, setOrders] = useState([]);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [seriesData, setSeriesData] = useState([
    {
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          console.log(chart, w, e);
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "10%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      // categories: [
      //   ["Jan", "Doe"],
      //   ["Feb", "Smith"],
      //   ["March", "Williams"],
      //   "April",
      //   ["April", "Brown"],
      //   ["May", "Evans"],
      //   ["June", "Wilson"],
      //   ["July", "Roberts"],
      // ],

      // categories: [],
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
  });

  useEffect(() => {
    if (chartStats?.length > 0) {
      const filteredData = chartStats?.filter(
        (item) => item?.year === selectedChartYear?.value
      );
      filteredData.sort((a, b) => a.month - b.month);
      const months = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const categories = filteredData.map((item) => months[item.month - 1]);
      const totalRevenueData = filteredData.map((item) =>
        item.totalRevenue.toFixed(3)
      );

      setSeriesData([{ data: totalRevenueData }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
        },
      }));
    }
  }, [selectedChartYear, chartStats]);

  useEffect(() => {
    if (chartStats?.length > 0) {
      const uniqueYears = [...new Set(chartStats?.map((item) => item.year))];
      const uniquesYearValues = uniqueYears?.map((year) => ({
        label: year,
        value: year,
      }));
      setChartYears(uniquesYearValues);
      setSelectedChartYear(uniquesYearValues[0]);

      // const uniqueYears = [
      //   ...new Set(chartStats?.map((item) => item.year)),
      // ].map((year) => ({ label: year, value: year }));
      // setChartYears(uniqueYears);
    }
  }, [chartStats]);

  const handleSelect = (opt) => {
    setSelectedChartYear(opt);
  };

  const userCards = [
    {
      title: "total revenue",
      earnings: `$ ${userEarnings?.totalRevenue?.toFixed(2) || 0}`,
      showModal: false,
    },

    {
      title: "total Orders",
      earnings: userEarnings?.totalOrders?.toFixed(2) || 0,
      showModal: false,
    },
    {
      title: "average selling price",
      earnings: `$ ${userEarnings?.averageSellingPrice?.toFixed(2) || 0}`,
      showModal: false,
    },
    {
      title: "sales in present month",
      earnings: userEarnings?.salesInPresentMonth?.toFixed(2) || 0,
      showModal: true,
    },
  ];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  // useEffect(() => {
  //   dispatch(getAllOrdersAction(selectedOption));
  // }, [selectedOption]);

  useEffect(() => {
    dispatch(getAllTaxesAction(id, selectedYear));
  }, [id, selectedYear]);

  useEffect(() => {
    // if (userOrders?.length > 0) {
    setOrders(userOrders);
    const statusSet = new Set(userOrders.map((order) => order.status));
    setUniqueStatus(Array.from(statusSet));
    // }
  }, [userOrders]);

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);

  //   if (event?.target.value !== "") {
  //     let filterOrders = userOrders?.filter(
  //       (item) => item?.status === event.target.value
  //     );
  //     setOrders(filterOrders);
  //   } else {
  //     setOrders(userOrders);
  //   }
  // };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalOrderPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (allCertification.length > 0) {
      setCertifications(
        allCertification?.filter((item) => item.type !== "covid")
      );
      setCovidCertifications(
        allCertification?.filter((item) => item.type == "covid")
      );
    }
  }, [allCertification]);

  useEffect(() => {
    dispatch(getCertificationAction(id));
  }, [dispatch, id]);

  return (
    <div>
      <ViewOrderActivity
        modal={modal}
        toggle={toggleModal}
        singleOrder={singleOrder}
      />
      <EarnedRevenueModal
        modal={earnRevenueModal}
        toggle={toggleEarnRevenueModal}
      />
      <SubmitTaxFileModal
        modal={taxModal}
        toggle={toggleTaxModal}
        year={currentYear}
      />
      <Nav tabs responsive className="tabs_navigation">
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            User Details
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Revenue Generated
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Orders
          </NavLink>
        </NavItem>
        {user?.country === "US" && (
          <NavItem className="cursor-pointer">
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggle("4")}
            >
              Taxation
            </NavLink>
          </NavItem>
        )}
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "5" })}
            onClick={() => toggle("5")}
          >
            License & Certifications
          </NavLink>
        </NavItem>
        {/* <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "6" })}
            onClick={() => toggle("6")}
          >
            Covid Certifications
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="mt-5">
          <Row>
            <Col md={6} sm="12">
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={user?.firstName + " " + user?.lastName}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Email Address</Label>
                <Input type="text" value={user?.email} readOnly></Input>
              </FormGroup>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type="text"
                  value={user?.phoneNumber ? user?.phoneNumber : "N/A"}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Profession</Label>
                <Input
                  type="text"
                  value={user?.occupation?.name}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>{user?.organization ? "SSN" : "EIN"}</Label>
                <Input
                  type="text"
                  value={user?.businessId ? user.businessId : "N/A"}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Date Joined</Label>
                <Input
                  type="text"
                  value={formatTimestamp(user?.createdAt)}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6} sm="12">
              <Label>Skills</Label>
              <div className="mb-2">
                {user?.skills.length > 0 ? (
                  user?.skills?.map((skill) => {
                    return (
                      <span className="mr-1">
                        <Badge size="xlg" className="actionButton">
                          {skill}
                        </Badge>
                      </span>
                    );
                  })
                ) : (
                  <Badge color="danger">skills not found</Badge>
                )}
              </div>
              <Label>Equipments</Label>
              <div className="mb-2">
                {user?.equipments.length > 0 ? (
                  user?.equipments?.map((equipment) => {
                    return (
                      <span className="mr-1">
                        <Badge size="xlg" className="actionButton">
                          {equipment}
                        </Badge>
                      </span>
                    );
                  })
                ) : (
                  <Badge color="danger">Equipments not found</Badge>
                )}
              </div>
              <Label>Work Types</Label>
              <div className="mb-2">
                {user?.workTypes.length > 0 ? (
                  user?.workTypes?.map((type) => {
                    return (
                      <span className="mr-1">
                        <Badge size="xlg" className="actionButton">
                          {type}
                        </Badge>
                      </span>
                    );
                  })
                ) : (
                  <Badge color="danger">workTypes not found</Badge>
                )}
              </div>
              <div className="d-flex">
                <div className="mb-2">
                  <Label>City</Label>
                  <div>
                    <Badge size="xlg" className="actionButton">
                      {user?.city}
                    </Badge>
                  </div>
                </div>
                <div className="mx-3">
                  <Label>State</Label>
                  <div className="mb-2">
                    <Badge size="xlg" className="actionButton">
                      {user?.state}
                    </Badge>
                  </div>
                </div>
                <div>
                  <Label>Country</Label>
                  <div className="mb-2">
                    <Badge size="xlg" className="actionButton">
                      {user?.country}
                    </Badge>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2" className="mt-5">
          <Row>
            {userCards?.map((tile) => {
              return (
                <Col sm="6" className="mb-3 h-30">
                  <Card body className="shadow-lg">
                    <CardTitle className="text-capitalize text-center">
                      {tile?.title} - {tile?.earnings}
                    </CardTitle>
                    {tile?.showModal && (
                      <div className="text-center">
                        <Button
                          size="sm"
                          color="primary"
                          onClick={toggleEarnRevenueModal}
                        >
                          View Details
                        </Button>{" "}
                      </div>
                    )}
                  </Card>
                </Col>
              );
            })}
            {/* 
            <Col sm="6" className="mb-3 mb-lg-0">
              <Card body className="shadow-lg">
                <CardTitle>Earned Last 6 Months - 6$</CardTitle>
                <div>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={toggleEarnRevenueModal}
                  >
                    View Details
                  </Button>
                </div>
              </Card>
            </Col> */}
            <Col sm="12" className="mt-4 mb-3 mb-lg-0">
              <Card body className="shadow-lg">
                <Select
                  className="w-20"
                  options={chartYears}
                  value={selectedChartYear}
                  onChange={handleSelect}
                />
                <div className="rounded-md items-center gap-4 w-full flex p-2">
                  <div id="chart" className="w-full">
                    <ReactApexChart
                      className="w-full"
                      options={options}
                      series={seriesData}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3" className="mt-2">
          <Row className="mx-0">
            <div className="d-flex justify-content-end w-100">
              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  className="text-capitalize"
                  value={selectedOption}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setStatus(e.target.value);
                  }}
                >
                  <option value=" ">All Orders</option>
                  <option value="completed">Completed Orders</option>
                  <option value="active">Pending Orders</option>
                  <option value="cancelled">Cancelled Orders</option>
                  <option value="indisputed">Disputed Orders</option>
                </Input>
              </FormGroup>
            </div>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Job ID</th>
                  <th scope="col">Buyer Name</th>
                  <th scope="col">Service</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>

                  <th>Action</th>
                </tr>
              </thead>

              {!getStatsLoader ? (
                <tbody>
                  {orders && orders.length > 0 ? (
                    orders.map((order, index) => (
                      <tr key={index}>
                        <td>{order?._id}</td>
                        <td>{order?.buyer?.firstName}</td>
                        <td>
                          {order?.service
                            ? order?.service?.title
                            : order?.job?.title}
                        </td>
                        <td>{order?.totalPrice}</td>
                        <td>
                          {order?.status === "pending" ? (
                            <Badge color="warning">{order.status}</Badge>
                          ) : order?.status === "failed" ? (
                            <Badge color="danger">{order.status}</Badge>
                          ) : (
                            <Badge color="success">{order.status}</Badge>
                          )}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => {
                              toggleModal();
                              setSingleOrder(order);
                            }}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <p>No orders found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <Spinner
                        size="lg"
                        style={{
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Row>
          {totalOrderPages > 0 && (
            <div className="py-4">
              <nav aria-label="...">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalOrderPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "pagination px-1 justify-content-end mb-0"
                  }
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                />
              </nav>
            </div>
          )}
        </TabPane>
        {user?.country === "US" && (
          <TabPane tabId="4" className="mt-2">
            <Row className="mx-0">
              <div className="d-flex align-items-center justify-content-end w-100 my-3">
                <div className="mx-2">
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => {
                      toggleTaxModal();
                    }}
                  >
                    Submit Tax File
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => {
                      history.push(`/admin/previous-taxes/${id}`);
                    }}
                  >
                    Previous Taxes
                  </Button>
                </div>
                <FormGroup className="mb-0">
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Tax ID</th>
                    <th scope="col">Tax Year</th>
                    <th scope="col">Tax File</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {recentTaxes.length > 0 ? (
                  <tbody>
                    {recentTaxes?.map((tax, index) => (
                      <tr key={index}>
                        <td>{tax?._id}</td>
                        <td>{tax.year}</td>
                        <td>
                          <a
                            href={tax.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Tax File <i className="fa fa-download"></i>
                          </a>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            color="danger"
                            disabled={taxLoader}
                            onClick={() => {
                              dispatch(deleteTaxAction(tax?._id));
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <p className="mb-0 text-sm">No RECORD FOUND</p>
                        {/* <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        /> */}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Row>
          </TabPane>
        )}

        <TabPane tabId="5" className="mt-2">
          <Row className="mx-0">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">License/Certification</th>
                  <th scope="col">Type</th>
                  <th scope="col">Issued Date</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Actions</th> */}
                </tr>
              </thead>

              {!getCertificationLoader ? (
                <tbody>
                  {Certifications && Certifications.length > 0 ? (
                    Certifications?.map((certificate, index) => (
                      <tr key={index}>
                        <td>{certificate?._id}</td>
                        <td>
                          <a target="_blank" href={certificate?.file}>
                            Preview
                          </a>
                        </td>
                        <td className="text-capitalize">{certificate?.type}</td>
                        <td>{certificate?.issuedDate.split("T")[0]}</td>
                        <td>{certificate?.expiryDate.split("T")[0]}</td>
                        <td>
                          {certificate?.type === "covid" ? (
                            certificate?.status === "pending" ? (
                              <Badge color="warning">Pending</Badge>
                            ) : certificate?.status === "accept" ? (
                              <Badge color="success">Accepted</Badge>
                            ) : certificate?.status === "reject" ? (
                              <Badge color="danger">Rejected</Badge>
                            ) : null
                          ) : new Date(certificate?.expiryDate.split("T")[0]) <
                            new Date() ? (
                            <Badge color="danger">Expired</Badge>
                          ) : (
                            <Badge color="success">Active</Badge>
                          )}
                        </td>
                        {/* <td>
                          {certificate?.type && certificate?.type == "covid" ? (
                            <>
                              <Button
                                size="sm"
                                color="primary"
                                disabled={
                                  certificate?.status === "accept" ||
                                  certificate?.status === "reject"
                                }
                                onClick={() =>
                                  dispatch(
                                    updateCertificateStatus(
                                      id,
                                      certificate?._id,
                                      "accept"
                                    )
                                  )
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                disabled={
                                  certificate?.status === "accept" ||
                                  certificate?.status === "reject"
                                }
                                onClick={() =>
                                  dispatch(
                                    updateCertificateStatus(
                                      id,
                                      certificate?._id,
                                      "reject"
                                    )
                                  )
                                }
                              >
                                Reject
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <p>No License and Certifications found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <Spinner
                        size="lg"
                        style={{
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Row>
        </TabPane>
        {/* <TabPane tabId="6" className="mt-2">
          <Row className="mx-0">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">License/Certification</th>
                  <th scope="col">Type</th>
                  <th scope="col">Issued Date</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>

              {!getCertificationLoader ? (
                <tbody>
                  {covidCertifications && covidCertifications.length > 0 ? (
                    covidCertifications?.map((certificate, index) => (
                      <tr key={index}>
                        <td>{certificate?._id}</td>
                        <td>
                          <a target="_blank" href={certificate?.file}>
                            Preview
                          </a>
                        </td>
                        <td className="text-capitalize">{certificate?.type}</td>
                        <td>{certificate?.issuedDate.split("T")[0]}</td>
                        <td>{certificate?.expiryDate.split("T")[0]}</td>
                        <td>
                          {certificate?.type === "covid" ? (
                            certificate?.status === "pending" ? (
                              <Badge color="warning">Pending</Badge>
                            ) : certificate?.status === "accept" ? (
                              <Badge color="success">Accepted</Badge>
                            ) : certificate?.status === "reject" ? (
                              <Badge color="danger">Rejected</Badge>
                            ) : null
                          ) : new Date(certificate?.expiryDate.split("T")[0]) <
                            new Date() ? (
                            <Badge color="danger">Expired</Badge>
                          ) : (
                            <Badge color="success">Active</Badge>
                          )}
                        </td>
                        <td>
                          <>
                            <Button
                              size="sm"
                              color="primary"
                              disabled={
                                certificate?.status === "accept" ||
                                certificate?.status === "reject"
                              }
                              onClick={() =>
                                dispatch(
                                  updateCertificateStatus(
                                    id,
                                    certificate?._id,
                                    "accept"
                                  )
                                )
                              }
                            >
                              Accept
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              disabled={
                                certificate?.status === "accept" ||
                                certificate?.status === "reject"
                              }
                              onClick={() =>
                                dispatch(
                                  updateCertificateStatus(
                                    id,
                                    certificate?._id,
                                    "reject"
                                  )
                                )
                              }
                            >
                              Reject
                            </Button>
                          </>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <p>No License and Certifications found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <Spinner
                        size="lg"
                        style={{
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Row>
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default AsSellerTab;
